import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "d-flex align-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_sms_survey_dropdown = _resolveComponent("sms-survey-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": _ctx.itemsName,
    "show-selection": _ctx.showSelection
  }, {
    "body-cell-name-slot": _withCtx((props) => [
      (/active|draft/i.test(props.row.status.name))
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: _ctx.getRoute(props.row.status.name, props.row),
            class: "d-flex align-center font-color-inherit font-weight-normal blue-on-hover"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                name: "call_split",
                size: "xLarge",
                rotate: 180,
                color: "distinct--text mr-2"
              }),
              _createElementVNode("span", null, _toDisplayString(props.row.name), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_tm_icon, {
              name: "call_split",
              size: "xLarge",
              rotate: 180,
              color: "distinct--text mr-2"
            }),
            _createElementVNode("span", null, _toDisplayString(props.row.name), 1)
          ]))
    ]),
    "body-cell-status-slot": _withCtx((props) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(props.row.status)), null, 16)
    ]),
    "body-cell-actions": _withCtx((props) => [
      _createVNode(_component_sms_survey_dropdown, {
        survey: props.row
      }, null, 8, ["survey"])
    ]),
    _: 1
  }, 8, ["headers", "items", "items-name", "show-selection"]))
}