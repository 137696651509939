
import { defineComponent } from 'vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useSmsSurvey from '@/compositions/services/useSmsSurvey'

export default defineComponent({
  components: {
    TmButton,
    TmEmptyState,
  },
  setup() {
    const { openNewModal } = useSmsSurvey()

    return {
      openNewModal,
    }
  },
})
