
import type { PropType } from 'vue'
import { defineComponent, computed, toRefs } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import useSmsSurvey from '@/compositions/services/useSmsSurvey'
import type { SmsSurveyLine } from '@/definitions/services/smsSurvey/types'

export default defineComponent({
  components: {
    TmDropdown,
    TmTableActionButton,
    TmDropdownItem,
  },
  props: {
    survey: {
      type: Object as PropType<SmsSurveyLine>,
      required: true,
    },
  },
  setup(props) {
    const { survey } = toRefs(props)

    const { openDeleteModal, openDuplicateModal } = useSmsSurvey()

    const toDetails = computed(() => (survey.value.status.name === 'active')
      ? {
          name: 'base.services.smsSurveys.details.overview',
          params: {
            id: survey.value.id,
          },
        }
      : null
    )

    return {
      toDetails,
      openDeleteModal,
      openDuplicateModal,
    }
  },
})
