
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import SmsSurveyDropdown from '@/components/pages/services/smsSurvey/SmsSurveyDropdown.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { SmsSurveyLine } from '@/definitions/services/smsSurvey/types'

export default defineComponent({
  components: {
    SmsSurveyDropdown,
    TmStatus,
    TmTable,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<SmsSurveyLine[]>,
      required: true,
    },
    itemsName: {
      type: String,
      required: true,
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const getRoute = (type: string, row: any) => {
      if (type === 'active') return { name: 'base.services.smsSurveys.details.overview', params: { id: row.id } }
      if (type === 'draft') return { name: 'base.services.smsSurveysFlow' }
    }

    return {
      getRoute,
    }
  },
})
