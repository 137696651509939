import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_table_action_button = _resolveComponent("tm-table-action-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, null, {
    menu: _withCtx(() => [
      (_ctx.survey.status.name === 'draft')
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 0,
            icon: "edit",
            label: "Edit contact"
          }))
        : _createCommentVNode("", true),
      (_ctx.survey.status.name !== 'draft')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_tm_dropdown_item, {
              icon: "call_split",
              rotate: 180,
              label: "View flow"
            }),
            _createVNode(_component_tm_dropdown_item, {
              icon: "insert_chart",
              label: "View results",
              to: _ctx.toDetails
            }, null, 8, ["to"]),
            _createVNode(_component_tm_dropdown_item, {
              icon: "download",
              label: "Download replies"
            })
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_dropdown_item, {
        icon: "tmi-duplicate",
        label: "Duplicate",
        onClick: _ctx.openDuplicateModal
      }, null, 8, ["onClick"]),
      (_ctx.survey.status.name === 'active')
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 2,
            icon: "remove_circle",
            label: "Cancel"
          }))
        : (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 3,
            icon: "delete",
            label: "Delete",
            onClick: _ctx.openDeleteModal
          }, null, 8, ["onClick"]))
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_table_action_button)
    ]),
    _: 1
  }))
}