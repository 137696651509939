
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { SmsSurveyLine } from '@/definitions/services/smsSurvey/types'
import { useModes } from '@/compositions/modes'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import SmsSurveyFilter from '@/components/pages/services/smsSurvey/SmsSurveyFilter.vue'
import SmsSurveyTable from '@/components/pages/services/smsSurvey/SmsSurveyTable.vue'
import SmsSurveyEmpty from '@/components/pages/services/smsSurvey/SmsSurveyEmpty.vue'

export default defineComponent({
  name: 'ServicesSmsSurveys',
  components: {
    SmsSurveyEmpty,
    SmsSurveyTable,
    SmsSurveyFilter,
    PageContent,
  },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()

    const search = ref('')
    const selected = ref<SmsSurveyLine[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Survey name', value: 'name-slot' },
      { text: 'Recipients', value: 'recipients' },
      { text: 'Questions', value: 'questions' },
      { text: 'Replies', value: 'replies' },
      { text: 'Status', value: 'status-slot' },
      { text: 'Date created', value: 'dateCreated', format: (val: string) => formatDate(val) },
    ])
    const tableItems = ref<SmsSurveyLine[]>(getTableData('smsSurvey'))
    const filteredTableItems = computed<SmsSurveyLine[]>(() => {
      return tableItems.value.filter((item) => item.name.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      search,
      selected,
      tableHeaders,
      tableItems,
      isEmptyMode,
      filteredTableItems,
    }
  },
})
